/**
 * Fetch customizado para enviar parâmetros padrões esperados pelos webservices
 * @param url da requisição
 * @param req parametros da requisicao: method, headers, body, timeout
 */

import axios from 'axios';
import router from '@/router';
import {useAlertaStore} from "@/store/modules/alertas";
import {useAdminStore} from "@/store/modules/admin";

export default async function customFetch(
  url,
  req,
  callbackErro = null,
  portal = process.env.VUE_APP_URL_API + process.env.VUE_APP_API_VERSION,
) {
  let urlHomolog = portal + url;
  const {idevento} = router.currentRoute.params;

  const semIdEvento = [
    '/evento'
  ]

  if (!semIdEvento.includes(url.split('?')[0]) && idevento && !url.includes('filter[idevento][]=')) {
    if (!urlHomolog.includes('?')) {
      urlHomolog += `?filter[idevento][]=${idevento}`;
    } else {
      urlHomolog += `&filter[idevento][]=${idevento}`;
    }
  }

  let method = req.method

  if (!method) {
    method = "GET"
  }

  method = method.toLowerCase();
  let token = window.localStorage.getItem('token');

  let options = {
    method,
    headers: {
      Authorization: "Bearer " + token,
      ...req.headers,
    },
    timeout: req.timeout || 60000
  };

  // inserindo corpo se método != get
  if (!!method && method.toUpperCase() !== "get") {
    options["data"] = JSON.stringify({
      ...req.body,
    });
    options["headers"] = {
      ...options["headers"],
      "Content-Type": "application/json"
    }
  }

  let axios_response = await axios(urlHomolog, options).catch((e) => {
    const {response} = e;

    if (callbackErro) {
      return callbackErro(e);
    }

    const {info, erro} = useAlertaStore();
    const {logout, usuarioLogado} = useAdminStore();

    if (e.response.status && e.response.status === 401) {
      const logado = usuarioLogado;
      const redirectTo = `${location.pathname}`;
      if (!["LoginAdmin"].includes(router.currentRoute.name)) {
        info('Sua sessão expirou');
        logout();

        if (!!logado && logado.role !== 'consumidor') {
          router.push(`/admin/login?redirectTo=${redirectTo}`);
          return;
        }

        router.push(`/login?redirectTo=${redirectTo}`);
        return;
      }
    }

    if (e.response.status && e.response.status === 403) {
      erro('Usuário não tem permissão.')
      router.push({
        name: 'PageError',
        params: {typeError: 403}
      })
      return;
    }

    if (e.response.status && e.response.status === 404) {
      if (!["LoginAdmin"].includes(router.currentRoute.name)) {
        router.push({
          name: 'PageError',
          params: {typeError: 404}
        })
        return;
      }
    }

    if (e.response.status && e.response.status === 500) {
      erro(e.response.data.message);
      return;
    }

    throw new Error(response.data.message)
  });

  return {
    status: axios_response.status ?? null,
    statusText: axios_response.statusText ?? null,
    message: JSON.parse(axios_response.request.response).message,
    headers: axios_response.headers,
    text: function () {
      return new Promise((resolve, reject) => {
        resolve(axios_response.data);
      })
    },
    json: function () {
      return new Promise((resolve, reject) => {
        resolve(axios_response.data);
      })
    }
  }
}