export default {
    path: '/consumidor',
    component: () => import(/* webpackChunkName: "page-consumidor" */'@/pages/PageConsumidor.vue'),
    meta: {isAuth: true},
    children: [
        {
            path: 'inicio',
            name: 'ConsumidorHome',
            component: () => import(/* webpackChunkName: "page-dashboard-consumidor" */ '@/views/ConsumidorHomeView.vue'),
            meta: {title: 'ElevenCash | Dashboard'}
        },
        {
            path: 'recarga',
            name: 'ConsumidorRecarga',
            component: () => import(/* webpackChunkName: "page-consumidor-recarga" */ '@/views/profiles/consumidor/RecargaCreditos.vue'),
            meta: {title: 'ElevenCash | Recarga'}
        },
        {
            path: 'formapagamento',
            name: 'FormaPagamento',
            component: () => import(/* webpackChunkName: "page-consumidor-formapagamento" */ '@/views/profiles/consumidor/EscolhaFormaPagamento.vue'),
            meta: {title: 'ElevenCash | Forma de pagamento'}
        },
        {
            path: 'pagamentocartao',
            name: 'PagamentoCartaoCredito',
            component: () => import(/* webpackChunkName: "page-consumidor-pagamentocartao" */ '@/views/profiles/consumidor/PagamentoCartaoCredito.vue'),
            meta: {title: 'ElevenCash | Pagamento cartão de crédito'}
        },
        {
            path: 'minhasrecargas',
            name: 'MinhasRecargas',
            component: () => import(/* webpackChunkName: "page-consumidor-minhasrecargas" */ '@/views/profiles/consumidor/MinhasRecargas.vue'),
            meta: {title: 'ElevenCash | Minhas recargas'}
        },
        {
            path: 'cadastros/cadastrar-conta',
            name: 'PageConsumidorCadastroConta',
            component: () => import(/* webpackChunkName: "pages-cadastrar-conta" */ '@/views/profiles/consumidor/cadastros/CadastroConta.vue'),
            meta: {title: 'ElevenCash | Cadastro conta'}
        },
        {
            path: 'cadastros/Cadastrar-dados-usuario',
            name: 'PageConsumidorCadastroUsuario',
            component: () => import(/* webpackChunkName: "page-Cadastrar-dados-usuario" */ '@/views/profiles/consumidor/cadastros/DadosCadastroUsuario.vue'),
            meta: {title: 'ElevenCash | Dados pessoais'}
        }
    ]
}